<script setup lang="ts">
import {FontAwesomeIcon} from "@fortawesome/vue-fontawesome";

const { subscribe, isCompatible, permission } = useWebPush();
</script>

<template>

  <div v-if="isCompatible && permission === 'default'">
    <div class="flex items-center p-4 text-blue-800 bg-blue-50" role="alert">
      <button @click.stop="subscribe">
        <font-awesome-icon class="text-lg" icon="fa-regular fa-bell" />
      </button>
      <div class="ml-3 text-sm font-medium text-justify">
        <button @click.stop="subscribe" class="font-semibold underline hover:no-underline">Povolte si upozornění</button>, abych vám mohla dát vědět o nových zprávách, motivacích a dalších novinkách.
      </div>
    </div>
  </div>

</template>
