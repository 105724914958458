<script setup lang="ts">
import { ref, watch } from 'vue';
import {FontAwesomeIcon} from "@fortawesome/vue-fontawesome";
import { useScrollLock } from '@vueuse/core';
import PullToRefresh from 'pulltorefreshjs';
import { TransitionRoot } from '@headlessui/vue';
import {isInStandaloneMode} from "~/utils/browser";

onMounted(() => {
  if (!isInStandaloneMode()) {
    return;
  }

  PullToRefresh.init({
    mainElement: '#pullToRefresh',
    instructionsPullToRefresh: 'Potáhnětě pro načtení',
    instructionsReleaseToRefresh: 'Pusťte pro načtení',
    instructionsRefreshing: 'Načítání',
    onRefresh() {
      window.location.reload();
    },
  });
});

onBeforeUnmount(() => {
  PullToRefresh.destroyAll();
});

const isMenuOpen = ref(false);
const isLocked = useScrollLock(document.body);
watch(isMenuOpen, async (open) => {
    isLocked.value = open;
})

const { user } = useAuth();
const menu = ref([
  ['Afirmace', '/affirmation', 'icon-[iconamoon--heart-light] text-xl'],
  ['Komunita', '/community', 'icon-[iconamoon--news-light] text-xl'],
  ['Kurzy', '/courses', 'icon-[fluent-mdl2--education] text-xl'],
  ['Meditace', '/meditations', 'icon-[ph--flower-lotus-light] text-xl'],
  ['Profil', '/', 'icon-[iconamoon--profile-circle-light] text-xl'],
  ['Košík', '/cart', 'icon-[iconamoon--shopping-card-light] text-xl'],
  ['Obchodní podmínky', '/tos', 'icon-[iconamoon--file-document-light] text-xl'],
]);
if (user.value === null) {
  menu.value = [
    ['Přihlášení', '/login', 'icon-[iconamoon--profile-circle-light] text-xl'],
    ['Registrace', '/register', 'icon-[carbon--user-profile] text-xl'],
    ['Obchodní podmínky', '/tos', 'icon-[iconamoon--file-document-light] text-xl'],
  ];
}
</script>

<template>
  <div>
    <VitePwaManifest />
    <!-- Header -->
    <div class="fixed left-0 top-0 h-[75px] w-full bg-white flex flex-nowrap items-center justify-between border-b p-2 px-6 z-[300]">
      <NuxtLink to="/">
        <img src="/logo-wide.png" class="h-8 sm:h-12">
      </NuxtLink>

      <button
        class="border flex items-center justify-center w-12 h-12 text-xl rounded-lg text-energy"
        :class="{'shadow-inner': isMenuOpen, 'shadow': !isMenuOpen}"
        type="button"
        @click.stop="isMenuOpen = !isMenuOpen"
      >
        <svg v-show="!isMenuOpen" viewBox="0 0 24 24" class="w-6 h-6 fill-energy"><path d="M22 10.5A1.5 1.5 0 0020.5 9h-17a1.5 1.5 0 100 3h17a1.5 1.5 0 001.5-1.5zm0-5A1.5 1.5 0 0020.5 4h-17a1.5 1.5 0 100 3h17A1.5 1.5 0 0022 5.5zM20 15v3.161c0 .746-.368 1.161-1 1.161s-1-.576-1-1.322v-3h-1v3c0 1.526.981 2.168 2 2.168 1.019 0 2-.481 2-2.007V15h-1zm-5 3l-2-3h-1v5h1v-3l2 3h1v-5h-1v3zm-7 2h3v-1H9v-1h2v-1H9v-1h2v-1H8v5zm-2-5h1v5H6v-3l-1 2.133L4 17v3H3v-5h1l1 2.345L6 15z"></path></svg>
        <font-awesome-icon v-show="isMenuOpen" icon="fa-solid fa-times" />
      </button>
    </div>

    <!-- Menu -->
    <TransitionRoot
      :show="isMenuOpen"
      class="fixed top-0 left-0 pt-[75px] bg-confidence w-full h-screen overflow-y-scroll z-[299]"
      as="div"
      enter="transition transform duration-[400ms]"
      enter-from="opacity-0 -translate-y-full"
      enter-to="opacity-100 translate-y-0"
      leave="transition transform duration-[400ms]"
      leave-from="opacity-100 translate-y-0"
      leave-to="opacity-0 -translate-y-full"
    >
      <div class="flex flex-col w-full">
        <NuxtLink
          v-for="[name, url, icon] in menu"
          :key="url"
          :to="url"
          class="flex items-center px-6 py-5 space-x-6 w-full text-white focus:outline-none focus:text-energy hover:text-energy border-y border-transparent hover:border-energy"
          @click="isMenuOpen = false"
        >
          <span :class="icon"></span>
          <span class="leading-4" v-text="name"></span>
        </NuxtLink>
      </div>
    </TransitionRoot>

    <!-- Content -->
    <div class="pt-[75px] pb-6 flex flex-col min-h-screen max-w-[800px] mx-auto">
      <div id="pullToRefresh"></div>
      <WebPushSubscribe />
      <slot></slot>
    </div>
  </div>
</template>
